import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import AuthService from "../../services/AuthService";
import {setToken, setUser} from "../../store/reducers/auth";

export const TokenAuth = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('token')) {
            dispatch(setToken(searchParams.get('token')));

            AuthService.validate().then(data => {
                const {token, user} = data;

                dispatch(setUser(user));

                navigate('/dashboard');
            })
        }
    }, [dispatch, navigate])

    return (
        <></>
    );
}
