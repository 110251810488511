import {WelcomeHeader} from "../components/Header/WelcomeHeader";
import {WelcomeSlider} from "../components/Slider/WelcomeSlider";
import "../assets/styles/pages/home.scss"
import {SetPasswordForm} from "../components/Form/SetPasswordForm";

export const SetPassword = () => {
    return (
        <div>
            <WelcomeHeader />

            <div className="home grid-50">

                <WelcomeSlider />

                <SetPasswordForm />

            </div>
        </div>
    )
}
