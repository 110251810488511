import pageLeft from '../../assets/img/icon/page-left.svg';
import pageRight from '../../assets/img/icon/page-right.svg';
import React, {useEffect, useState} from "react";
import InvoiceService from "../../services/InvoiceService";
import arrowLeftIcon from "../../assets/img/icon/arrow-left-line.svg";
import invoiceIcon from "../../assets/img/icon/file-line.svg";
import {API_ROOT} from "../../config";
import closeIcon from "../../assets/img/icon/close-line.svg";
import checkmarkIcon from "../../assets/img/icon/check-line.svg";
import {useSelector} from "react-redux";
import {AlertModal} from "../Modal/AlertModal";

export const Invoices = () => {
    const [invoices, setProducts] = useState([]);
    const [modalActive, setModalActive] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('All');
    const [totalPages, setTotalPages] = useState(0);
    const [totalInvoices, setTotalInvoices] = useState(0);
    const [initialized, setInitialized] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const [alertModalActive, setAlertModalActive] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState('');
    const [alertModalAction, setAlertModalAction] = useState(null);
    const [alertModalTitle, setAlertModalTitle] = useState(null);


    useEffect(() => {
        InvoiceService.getInvoices({
            page: currentPage,
            filter: filter,
        }).then(data => {
            setProducts(data.invoices);
            setTotalPages(data.pages);
            setTotalInvoices(data.total);
            setInitialized(true);
        })
    }, [currentPage, filter]);

    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState('');
    const openInvoiceModal = index => {
        setSelectedInvoice(invoices[index]);
        setSelectedInvoiceIndex(index);

        const date = new Date();
        const dateString = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

        setInvoiceDate(dateString);

        setModalActive(true);
    }

    useEffect(() => {
        const handleResize = () => {
            if (document.documentElement.clientWidth < 1300) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const getItemTotal = item => {
        if (parseFloat(item.invoice_item_price) && parseFloat(item.invoice_item_quantity)) {
            return '$' + (item.invoice_item_price * item.invoice_item_quantity).toFixed(2);
        }

        return '';
    }

    const getInvoiceTotal = () => {
        let total = 0.00;
        for (let i = 0; i < selectedInvoice?.invoice_items?.length; i++) {
            let itemTotal = parseFloat(
                getItemTotal(selectedInvoice?.invoice_items[i]).replace('$', '')
            );

            if (itemTotal) {
                total += itemTotal;
            }
        }

        return '$' + total.toFixed(2);
    }

    const handleDeleteInvoice = (invoice) => {
        if (user && user.role !== 'distributor') {
            return;
        } else if (invoice) {
            InvoiceService.deleteInvoice(invoice.invoice_id)
                .then((response) => {

                    invoices.splice(selectedInvoiceIndex, 1);
                    setModalActive(false);
                    setAlertModalTitle('Success');
                    setAlertModalMessage('Statement #' + invoice.invoice_number + ' deleted successfully');
                    setAlertModalAction(null);
                });
        }
    }

    const handleCloseAlertModal = () => {
        setAlertModalActive(false);
        setAlertModalTitle(null);
        setAlertModalAction(null);
    }

    // Clear viewing invoice id on modal close
    useEffect(() => {
        if (modalActive === false) {
            setSelectedInvoice(null);
            setSelectedInvoiceIndex(null);
        }
    }, [modalActive])

    return (
        <div>
        <div className="products-list">
            <div className="products-list-header">
                <h2 className="title">
                    Statements <span className="product-count">{totalInvoices}</span>
                </h2>

                <div className="status-btn-container">
                    <button type="button" className={"status " + (filter === 'All' ? 'active' : null)} onClick={() => {
                        setCurrentPage(1);
                        setFilter('All');
                    }}>
                        All
                    </button>

                    <button type="button" className={"status " + (filter === 'Accepted' ? 'active' : null)} onClick={() => {
                        setCurrentPage(1);
                        setFilter('Accepted')
                    }}>
                        Accepted
                    </button>

                    <button type="button" className={"status " + (filter === 'Pending' ? 'active' : null)} onClick={() => {
                        setCurrentPage(1);
                        setFilter('Pending');
                    }}>
                        Pending
                    </button>
                </div>
            </div>

            <div className="card-container">

                {(initialized && invoices.length === 0) ? (
                    <div className="card">
                        <div className="top">
                            <h2 className="card-title">
                                No Statements Found
                            </h2>
                        </div>
                    </div>
                ) : null}
                {invoices.map((invoice, index) => {
                    return (
                        <div className="card invoice-card" key={'invoice_' + index} onClick={() => openInvoiceModal(index)}>
                            <div className="top">
                                    {user?.role === 'distributor' ?
                                        <div className="card-title">
                                            {invoice.contractor.profile_company} - {invoice.contractor.contractor_title}
                                        </div>
                                        :
                                        <div className="card-title">
                                            Statement #{invoice.invoice_number}
                                        </div>
                                    }

                                <div className="card-total">
                                    ${invoice.invoice_total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>


                                {user?.role === 'distributor' && (
                                    <div className="bottom">
                                        <span className="date">#{invoice.invoice_number}</span>
                                        <span className="dot">•</span>
                                        <span className="date">{invoice.invoice_date}</span>
                                        <span className="dot">•</span>
                                        <span className={invoice.invoice_status.toLowerCase()}>{invoice.invoice_status}</span>
                                    </div>
                                )}
                                {user?.role === 'contractor' && (
                                    <div className="bottom">
                                        <span className={invoice.invoice_status.toLowerCase()}>{invoice.invoice_status}</span>
                                    </div>
                                )}
                        </div>
                    );
                })}

            </div>

            <div className="pagination">
                {totalPages > 1 && (
                    <>
                        {currentPage > 1 && (
                            <a href="#" className="page-left" onClick={() => setCurrentPage(currentPage - 1)}>
                                <img src={pageLeft} alt="Page Left" />
                            </a>
                        )}

                        {Array.from(Array(totalPages), (e, i) => {
                            return <a href="#" className={"page-number " + (currentPage === i + 1 ? 'active' : null)} onClick={() => setCurrentPage(i + 1)} key={'invoices_page_' + i}>
                                {i + 1}
                            </a>
                        })}

                        {currentPage + 1 <= totalPages && (
                            <a href="#" className="page-right" onClick={() => setCurrentPage(currentPage + 1)}>
                                <img src={pageRight} alt="Page Right" />
                            </a>
                        )}
                    </>
                )}
            </div>
        </div>

            <AlertModal active={alertModalActive} close={() => handleCloseAlertModal()} message={alertModalMessage} confirmAction={alertModalAction} title={alertModalTitle} />

            <div className={"invoice-modal " + (modalActive ? 'active' : 'inactive')}>
                <div className="modal-card">
                    <div className="card-header">
                        <div className="file-info">
                            <img src={arrowLeftIcon} alt="Back" className="invoice-back-arrow"/>

                            <img src={invoiceIcon} className="invoice-icon" alt="" />

                            <div>
                                <p>
                                    Statement #{selectedInvoice?.invoice_number}
                                </p>

                                <a href={API_ROOT + '/uploaded/invoices/' + selectedInvoice?.invoice_file} target="_blank" rel="noreferrer">
                                    {selectedInvoice?.invoice_file}
                                </a>
                            </div>
                        </div>

                        <div className="actions">
                            {
                                user?.role === 'distributor' && selectedInvoice?.invoice_payment_status !== 'Paid' && selectedInvoice?.invoice_status !== 'Accepted' ? (
                                    <button type="button" className="btn btn-danger" onClick={() => { setAlertModalTitle("Delete Statement #" + selectedInvoice?.invoice_number); setAlertModalAction(() => () => handleDeleteInvoice(selectedInvoice)); setAlertModalMessage("Are you sure you want to delete statement #" + selectedInvoice?.invoice_number + "?"); setAlertModalActive(true);}}>
                                        Delete Statement
                                    </button>
                                ) : null
                            }
                            <button type="button" className="invoice-modal-close" onClick={() => setModalActive(false)}>
                                <img src={closeIcon} alt="Close" />
                            </button>
                        </div>
                    </div>

                    <form id="invoice_modal_form" className="card-body">
                        <div className="body-header">
                                {
                                    user?.role === 'distributor' ?
                                        <div className="name">
                                            {selectedInvoice?.contractor?.profile_company} - {selectedInvoice?.contractor?.contractor_title}
                                        </div>
                                        :
                                        <div className="name">
                                            {selectedInvoice?.distributor_company} - {selectedInvoice?.distributor_name}
                                        </div>
                                }

                            <div className="date">
                                {user?.role === 'contractor' && (
                                    <input type="text" placeholder={'Invoice #'} value={selectedInvoice?.invoice_number} className={'invoice-number-input'} disabled={true} />
                                )}
                                {user?.role === 'distributor' && (
                                    <label className="stacked-input">
                                        Reward Year
                                        <input type="text" name="invoice_date" className="input-small" value={selectedInvoice?.invoice_reward_year} disabled={true} />
                                    </label>
                                )}
                                <input type="text" name="invoice_date" value={invoiceDate} disabled={true} />
                            </div>
                        </div>

                        <div className="product-grid">
                            <div className="grid-container">
                                <div className="grid-header">
                                    Product #
                                </div>

                                <div className="grid-header">
                                    Atlantic Item #
                                </div>

                                <div className="grid-header">
                                    Description
                                </div>

                                <div className="grid-header">
                                    Price
                                </div>

                                <div className="grid-header">
                                    QTY
                                </div>

                                <div className="grid-header">
                                    Total
                                </div>

                                <div className="grid-header">
                                    AWG/OASE Product
                                </div>

                                <div className="grid-header last-column"></div>
                            </div>


                            <div className="grid-container">
                                {selectedInvoice?.invoice_items?.map((item, index) => {
                                    return (
                                        <React.Fragment key={'invoice-item-' + index}>
                                            <div className="grid-item product-id">
                                                <label className="mobile">Product #</label>
                                                <input type="text" value={item.invoice_item_item_id} disabled={true} />
                                            </div>

                                            <div className="grid-item item-id">
                                                <label className="mobile">Atlantic Item #</label>
                                                <input type="text" value={item.product_item_number} disabled={true} />
                                            </div>

                                            <div className="grid-item description">
                                                <label className="mobile">Description</label>
                                                <input type="text" value={item.invoice_item_description} disabled={true} />
                                            </div>

                                            <div className={"grid-item price-qty price " + (item?.possible_error ? 'error' : '')}>
                                                <label className="mobile">Price</label>
                                                <input type="text" value={'$' + item.invoice_item_price} disabled={true} />
                                            </div>

                                            <div className="grid-item price-qty qty" style={isMobile ? {gridRow: index * 4 + 1} : {}}>
                                                <label className="mobile">Qty</label>
                                                <input type="text" value={item.invoice_item_quantity} disabled={true} />
                                            </div>

                                            <div className="grid-item total">
                                                <label className="mobile">Total</label>
                                                <input type="text"
                                                       disabled={true}
                                                       value={getItemTotal(item)} readOnly />
                                            </div>

                                            <div
                                                className={"grid-item checkbox-container " + (item.invoice_item_is_awg_oase == '1' ? 'checked' : '')}>

                                                <div className={"stylish-checkbox readonly"}>
                                                    <img src={checkmarkIcon} alt="Checkmark" className="check"/>
                                                </div>

                                                <label className="mobile">AWG/OASE Product</label>
                                            </div>

                                            <div className="grid-item trash">

                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </form>

                    <div className="card-footer">
                        <div>
                            Total
                        </div>

                        <div className="products-total">
                            {getInvoiceTotal()}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
