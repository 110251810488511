import {Http} from "../utils/Http";
import {API_ROOT} from "../config";

export default {
    async login(email, password) {
        let response = await Http().post(`${API_ROOT}/auth/login`, {
            email,
            password,
        });

        return response.data;
    },
    async validate() {
        let response = await Http().get(`${API_ROOT}/auth/validate`);

        return response.data;
    },
    async forgot(email) {
        const response = await Http().post(`${API_ROOT}/auth/forgot-password`, {
            email,
        });

        return response.data;
    },
    async reset(password, code) {
        const response = await Http().post(`${API_ROOT}/auth/reset`, {
            password,
            code,
        });

        return response.data;
    },
    async update(user) {
        const response = await Http().post(`${API_ROOT}/auth/update-info`, user);

        return response.data;
    }
}
