import logo from '../../assets/img/AO_REWARDS.png';
import './style.scss';
import {useEffect, useState} from "react";
import AuthService from "../../services/AuthService";
import {useDispatch} from "react-redux";
import {setToken, setUser} from "../../store/reducers/auth";
import {useNavigate, useSearchParams} from "react-router-dom";

export const ResetPasswordForm = () => {
    const [resetCode, setResetCode] = useState('');
    const [password, setPassword] = useState(null);
    const [passwordConfirm, setPasswordConfirm] = useState(null);
    const [error, setError] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setResetCode(searchParams.get('code'));
    }, [searchParams]);

    const login = async (e) => {
        e.preventDefault();

        if (!password) {
            setError('Please enter your password');
            return;
        }

        if (password !== passwordConfirm) {
            setError('Password mismatch');
            return;
        }

        if (!resetCode) {
            setError('Password reset code is required');
            return;
        }

        try {
            const login = await AuthService.reset(password, resetCode);
            const {token, user} = login;

            dispatch(setToken(token));
            dispatch(setUser(user));

            navigate('/dashboard');
        } catch (error) {
            console.log('err', error);
            setError(error);
        }
    };

    return (
        <div className="home-form-container">
            <img src={logo} alt="logo" width="260" height="69"
                 className="form-logo tablet-hidden" />

            <form method="POST" className="form signin-container" onSubmit={e => login(e)}>
                <h1 className="title">Reset Password</h1>

                {error && (
                    <div className="form-errors">
                        <p>{error}</p>
                    </div>
                )}

                <div className="input-container">
                    <input type="text" placeholder="Reset Code" defaultValue={resetCode} onChange={e => setResetCode(e.target.value)} />
                </div>

                <div className="input-container">
                    <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                </div>

                <div className="input-container">
                    <input type="password" placeholder="Confirm Password" onChange={e => setPasswordConfirm(e.target.value)} />
                </div>

                <input type="submit" value="Log in" className="btn btn-primary btn-wide submit" />
            </form>
        </div>
    );
}
