import logo from '../../assets/img/AO_REWARDS.png';
import './style.scss';
import {useState} from "react";
import AuthService from "../../services/AuthService";
import {useDispatch} from "react-redux";
import {setToken, setUser} from "../../store/reducers/auth";
import {useNavigate} from "react-router-dom";

export const WelcomeForm = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = async (e) => {
        e.preventDefault();

        if (!email) {
            setError('Please enter a valid email address');
            return;
        }

        if (!password) {
            setError('Please enter your password');
            return;
        }

        try {
            const login = await AuthService.login(email, password);
            const {token, user} = login;

            dispatch(setToken(token));
            dispatch(setUser(user));

            navigate('dashboard');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="home-form-container">
            <img src={logo} alt="logo" width="260" height="69"
                 className="form-logo tablet-hidden" />

                <form method="POST" className="form signin-container" onSubmit={e => login(e)}>
                    <h1 className="title">Welcome back</h1>

                    {error && (
                        <div className="form-errors">
                            <p>{error}</p>
                        </div>
                    )}

                    <div className="input-container">
                        <input type="email" placeholder="Email" name="email" onChange={e => setEmail(e.target.value)} />
                    </div>

                    <div className="input-container password">
                        {showPassword ? (
                            <>
                                <input type="text" placeholder="Password" name="pass" value={password} onChange={e => setPassword(e.target.value)} />
                                <p>
                                    <a href={'#'} onClick={() => setShowPassword(false)}>Hide password</a>
                                </p>
                            </>
                        ) : (
                            <>
                                <input type="password" placeholder="Password" name="pass" value={password} onChange={e => setPassword(e.target.value)} />
                                <p>
                                    <a href={'#'} onClick={() => setShowPassword(true)}>Show password</a>
                                </p>
                            </>
                        )}
                    </div>

                    <input type="submit" value="Log in" className="btn btn-primary btn-wide submit" />
                </form>

                <p className="sign-up">
                    Don’t have an account? <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=m8CUPfaJFUaKtC6U2t7_8hc_nmGsNwRKiJLssPIRfsFUOTlRNjdEMVFZR1pBQjNZS0lLNENURjlYRy4u">Click here to sign up</a>
                </p>

                <p className="sign-up">
                    Forgot password? <a href={'/forgot-password'}>Click here to reset it</a>
                </p>
        </div>
    );
}
