import mobileLogo from '../../assets/img/AO_REWARDS.png';
import hamburger from '../../assets/img/icon/hamburger.svg';
import closeLine from '../../assets/img/icon/close-line.svg';
import styles from './styles.scss';
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export  const MobileHeader = ({setMobileMenuOpen}) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenuOpen = () => {
        setMobileMenuOpen(!isOpen)
        setIsOpen(!isOpen);
    }

    return (
        <header className="site-header mobile-header">
            <img src={mobileLogo} alt="Logo" className="logo d-block-tablet" onClick={() => navigate('/dashboard')} />

                <div className={"hamburger-container " + (isOpen ? 'active' : '')} onClick={toggleMenuOpen}>
                    <img src={hamburger} alt="Menu" className="hamburger" />

                    <img src={closeLine} alt="Close Menu" className="mobile-menu-close" />
                </div>
        </header>
    );
}
