import pageLeft from '../../assets/img/icon/page-left.svg';
import pageRight from '../../assets/img/icon/page-right.svg';
import {useEffect, useState} from "react";
import InvoiceService from "../../services/InvoiceService";

export const ProductsList = () => {
    const [products, setProducts] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('All');
    const [totalPages, setTotalPages] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        InvoiceService.getProducts({
            page: currentPage,
            filter: filter,
        }).then(data => {
            setProducts(data.products);
            setTotalPages(data.pages);
            setTotalProducts(data.total);
            setInitialized(true);
        })
    }, [currentPage, filter]);

    return (
        <div className="products-list">
            <div className="products-list-header">
                <h2 className="title">
                    Products <span className="product-count">{totalProducts}</span>
                </h2>

                <div className="status-btn-container">
                    <button type="button" className={"status " + (filter === 'All' ? 'active' : null)} onClick={() => {
                        setCurrentPage(1);
                        setFilter('All');
                    }}>
                        All
                    </button>

                    <button type="button" className={"status " + (filter === 'Accepted' ? 'active' : null)} onClick={() => {
                        setCurrentPage(1);
                        setFilter('Accepted')
                    }}>
                        Accepted
                    </button>

                    <button type="button" className={"status " + (filter === 'Pending' ? 'active' : null)} onClick={() => {
                        setCurrentPage(1);
                        setFilter('Pending');
                    }}>
                        Pending
                    </button>
                </div>
            </div>

            <div className="card-container">

                {(initialized && products.length === 0) ? (
                    <div className="card">
                        <div className="top">
                            <h2 className="card-title">
                                No Products Found
                            </h2>
                        </div>
                    </div>
                ) : null}
                {products.map((product, index) => {
                    return (
                        <div className="card" key={'product_' + index}>
                            <div className="top">
                                <div className="card-title">
                                    Product #{product.invoice_item_item_id} &nbsp; <span className="description">{product.invoice_item_description}</span>
                                </div>

                                <div className="card-total">
                                    ${product.total_value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                            </div>

                            <div className="bottom">
                                <span className="date">Statement #{product.invoice_number} &nbsp;&nbsp; Qty: {product.total_quantity}</span>
                                <span className="dot">•</span>
                                <span className={product.invoice_status.toLowerCase()}>{product.invoice_status}</span>
                            </div>
                        </div>
                    );
                })}

            </div>

            <div className="pagination">
                {totalPages > 1 && (
                    <>
                        {currentPage > 1 && (
                            <a href="#" className="page-left" onClick={() => setCurrentPage(currentPage - 1)}>
                                <img src={pageLeft} alt="Page Left" />
                            </a>
                        )}

                        {Array.from(Array(totalPages), (e, i) => {
                            return <a href="#" className={"page-number " + (currentPage === i + 1 ? 'active' : null)} onClick={() => setCurrentPage(i + 1)} key={'products_page_' + i}>
                                {i + 1}
                            </a>
                        })}

                        {currentPage + 1 <= totalPages && (
                            <a href="#" className="page-right" onClick={() => setCurrentPage(currentPage + 1)}>
                                <img src={pageRight} alt="Page Right" />
                            </a>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
