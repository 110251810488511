import "../../assets/styles/pages/page.scss"
import {DistributorDashboard} from "./DistributorDashboard";
import {ContractorDashboard} from "./ContractorDashboard";

export const Dashboard = ({userType}) => {
    return (
        <div className={'full-height'}>
                {userType === 'contractor' ? (
                    <ContractorDashboard />
                ) : (
                    <DistributorDashboard />
                )}
        </div>
    )
}
