import {Http} from "../utils/Http";
import {API_ROOT} from "../config";

export default {
    async searchContractors(term) {
        let response = await Http().get(`${API_ROOT}/contractors/search-contractors`, {
            params: {
                term
            }
        });

        return response.data;
    },
    async getSettingsData() {
        let response = await Http().get(`${API_ROOT}/auth/settings`);

        return response.data;
    },
}
