import {Http} from "../utils/Http";
import {API_ROOT} from "../config";

export default {
    async getAll(params = null) {
        let response = await Http().get(`${API_ROOT}/invoices/index`, {
            params
        });

        return response.data;
    },
    async insert(invoice) {
        let response = await Http().post(`${API_ROOT}/invoices/insert`, {
            invoice
        });

        return response.data;
    },
    async getProducts(params = null) {
        let response = await Http().get(`${API_ROOT}/invoices/get-products`, {
            params,
        });

        return response.data;
    },
    async searchProducts(term) {
        let response = await Http().post(`${API_ROOT}/invoices/search-products`, {
            term,
        });

        return response.data;
    },
    async getInvoices(params = null) {
        let response = await Http().get(`${API_ROOT}/invoices/get-invoices`, {
            params,
        });

        return response.data;
    },
    async getPastRewards(params = null) {
        let response = await Http().get(`${API_ROOT}/invoices/past-rewards`, {
            params,
        });

        return response.data;
    },
    async uploadInvoice(data, progressCallback) {
        let response = await Http({contentType: 'multipart/form-data'}).post(`${API_ROOT}/invoices/uploadInvoice`, data, {
            onUploadProgress: e => progressCallback(e.loaded)
        });

        return response.data;
    },
    async deleteInvoice(invoiceId) {
        let response = await Http().post(`${API_ROOT}/invoices/delete/${invoiceId}`);

        return response.data;
    },
}
