import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {persistStore} from "redux-persist";
import authReducers from './reducers/auth';

export const store = configureStore({
    reducer: {
        auth: authReducers,
    },
    middleware: getDefaultMiddleware({
        // serializableCheck: {
        //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // },
        serializableCheck: false, //Need onClose action for configurator store
    }),
});

export const persistor = persistStore(store);
