import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {CHART_OPTIONS} from "../../config";
import {useEffect, useState} from "react";
import InvoiceService from "../../services/InvoiceService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const PastRewardsList = () => {
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [pastRewards, setPastRewards] = useState([]);
    const [lowReward, setLowReward] = useState(0);
    const [highReward, setHighReward] = useState(0);

    const [rewardsStart, setRewardsStart] = useState(1);
    const [allRewardsLoaded, setAllRewardsLoaded] = useState(false);

    const [rewardsDataPoints, setRewardsDataPoints] = useState([]);
    const [rewardsDataLabels, setRewardsDataLabels] = useState([]);

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        InvoiceService.getPastRewards().then(data => {
            let rewardsEarnedAllTime = 0;
            for (let i = 0; i < data.pastRewards.length; i++) {
                rewardsEarnedAllTime += parseFloat(data.pastRewards[i].invoice_year_total) * parseFloat('0.' + data.pastRewards[i].invoice_reward_value);
            }

            setTotalEarnings(rewardsEarnedAllTime.toFixed(2));

            setLowReward(data.min);
            setHighReward(data.max);

            setPastRewards(data.pastRewards);

            if (data.pastRewards.length === data.total) {
                setAllRewardsLoaded(true);
            }

            let rewardsData = data.pastRewards.map(reward => {
                return Math.round((reward.invoice_year_total - lowReward) / (highReward - lowReward) * 10);
            });

            let rewardsLabels = data.pastRewards.map(reward => {
                return `${reward.invoice_year}`;
            });

            if (rewardsData.length === 1) {
                rewardsData = [0, 1];
                rewardsLabels = [rewardsLabels[0], rewardsLabels[0]];
            }

            setRewardsDataPoints(rewardsData);
            setRewardsDataLabels(rewardsLabels);

            setInitialized(true);
        })
    }, []);

    const loadRewards = () => {
        InvoiceService.getPastRewards({page: rewardsStart + 1}).then(data => {

            if (data.min < lowReward) {
                setLowReward(data.min);
            }

            if (data.max > highReward) {
                setHighReward(data.max);
            }

            const loadRewards = pastRewards;
            loadRewards.push(...data.pastRewards);

            setPastRewards(loadRewards);

            setRewardsStart(rewardsStart + 1);

            if (loadRewards.length === data.total) {
                setAllRewardsLoaded(true);
            }
        })
    }

    return (
        <div className="past-rewards">
            {initialized && totalEarnings > 0 && (
                <div className="line-graph-container">

                    <div className="totals-container">
                        <div className="earned-text">
                            All Time Rewards
                        </div>

                        <div className="earned-total">
                            ${totalEarnings?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                    </div>


                    <div className="dashboard-graph active">
                        <Line options={CHART_OPTIONS} data={{
                            datasets: [{
                                data: rewardsDataPoints,
                                borderWidth: 6,
                                fill: false,
                                borderColor: 'rgba(255, 255, 255, 0.2)',
                            }],
                            labels: rewardsDataLabels,
                        }} />
                    </div>
                </div>
            )}

            <div className="card-container">
                <div className="card-header">
                    <div className="title">
                        Past Rewards Paid
                    </div>

                    {!allRewardsLoaded && (
                        <div className="view-more" onClick={loadRewards}>
                            View More
                        </div>
                    )}
                </div>

                <div className="card-body">

                    {(initialized && pastRewards.length === 0) ? (
                        <div className="row">
                            <div className="top">
                                <h2 className="card-title">
                                    No Past Rewards
                                </h2>
                            </div>
                        </div>
                    ) : null}
                    {pastRewards.map((reward, index) => {
                        return (
                            <div className="row" key={'reward_' + index}>
                                <div className="top">
                                    <div className="left">
                                        {reward.invoice_year}
                                    </div>

                                    <div className="right">
                                        +${(reward.invoice_year_total * ('0.' + reward.invoice_reward_value)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>

                                <div className="bottom">
                                    <div className="left">
                                        Paid on {reward.invoice_payment_date}
                                    </div>

                                    <div className="right">
                                        <span className="box">{reward.invoice_reward_value}%</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}
