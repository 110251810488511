import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import AuthService from "../../services/AuthService";
import {setUser} from "../../store/reducers/auth";
import UserService from "../../services/UserService";

export const AccountSettings = () => {
    const {user} = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const[userInfo, setUserInfo] = useState(user);
    const[password, setPassword] = useState('');
    const[confirmPass, setConfirmPass] = useState('');

    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);

    const [message, setMessage] = useState(null);

    const [distributors, setDistributors] = useState([]);
    const [contractors, setContractors] = useState([]);

    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        UserService.getSettingsData().then(data => {
            setStates(data.states);
            setCountries(data.countries);
            setDistributors(data.distributors);
            setContractors(data.contractors);
        })
    }, []);

    const getInfo = key => {
        return userInfo[key] ?? '';
    }

    const updateInfo = (key, value) => {
        const update = {...userInfo};
        update[key] = value;

        setUserInfo(update);
    }

    const submit = () => {
        const updateInfo = {...userInfo};
        if (password && password === confirmPass) {
            updateInfo['password'] = password;
            updateInfo['password_confirm'] = confirmPass;
        }

        AuthService.update(updateInfo).then(data => {
            setMessage(data.message);

            dispatch(setUser(data.user));

            setUpdated(!updated);
        })
    }

    useEffect(() => {
        document.getElementById('settings_top').scrollIntoView({behavior: "smooth"});
    }, [updated]);

    return (
        <div className="account-settings">
            <div className="card-container">
                <div className="card-header account-settings-header" id={'settings_top'}>
                    <div className="title">
                        Account Settings
                    </div>

                    {message ? (
                        <h2 style={{textAlign: 'center', fontWeight: 600, fontSize: 32}}>{message}</h2>
                    ) : null}
                </div>

                <div className="card-body">
                    <div className="row">

                        <div className="card-title">
                            User Information

                            {user.sales_rep_name ? (
                                <span className={'sales-rep-name'}>
                                    <span>
                                        <span className={'sales-rep-label'}>Sales Rep:</span> {user.sales_rep_name}
                                    </span>

                                    {user.sales_rep_secondary_name ? (
                                        <span className={'sales-rep-secondary'}>
                                            <span className={'sales-rep-label'}>Secondary Rep:</span> {user.sales_rep_secondary_name}
                                        </span>
                                    ) : null}
                                </span>
                            ) : null}
                        </div>

                        <div className="form-container">
                            <div className="form-group">
                                <label htmlFor="first_name">
                                    First Name
                                </label>
                                <input type="text" name="first_name" id="first_name" value={getInfo('profile_first_name')} onChange={e => updateInfo('profile_first_name', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="last_name">
                                    Last Name
                                </label>
                                <input type="text" name="last_name" id="last_name" value={getInfo('profile_last_name')} onChange={e => updateInfo('profile_last_name', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">
                                    Email
                                </label>
                                <input type="email" name="email" id="email" value={getInfo('user_email')} onChange={e => updateInfo('user_email', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="phone">
                                    Phone
                                </label>
                                <input type="tel" name="phone" id="phone" value={getInfo('profile_phone')} onChange={e => updateInfo('profile_phone', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="profile_company">
                                    Company Name
                                </label>
                                <input type="text" name="profile_company" id="profile_company" value={getInfo('profile_company')} onChange={e => updateInfo('profile_company', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="profile_title">
                                    Title
                                </label>
                                <input type="text" name="profile_title" id="profile_title" value={getInfo('profile_title')} onChange={e => updateInfo('profile_title', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="user_signup_date_formatted">
                                    Sign Up Date
                                </label>
                                <input type="text" name="user_signup_date_formatted" id="user_signup_date_formatted" value={getInfo('user_signup_date_formatted')} readOnly={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-container">
                <div className="card-body">
                    <div className="row">
                        <div className="card-title">
                            Address
                        </div>

                        <div className="form-container">

                            <div className="form-group">
                                <label htmlFor="address_street_1">
                                    Street Address 1
                                </label>
                                <input type="text" name="address_street_1" id="address_street_1" value={getInfo('address_street_1')} onChange={e => updateInfo('address_street_1', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="address_street_2">
                                    Street Address 2
                                </label>
                                <input type="text" name="address_street_2" id="address_street_2" value={getInfo('address_street_2')} onChange={e => updateInfo('address_street_2', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="address_city">
                                    City
                                </label>
                                <input type="text" name="address_city" id="address_city" value={getInfo('address_city')} onChange={e => updateInfo('address_city', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="state_id">
                                    State
                                </label>
                                <select name="state_id" id="state_id" value={getInfo('state_id')} onChange={e => updateInfo('state_id', e.target.value)} required>
                                    <option>Select State</option>
                                    {states.map((state, index) => {
                                        return (<option key={'state_' + index} value={state.state_id}>{state.state_name}</option>);
                                    })}
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="address_zip">
                                    Zip
                                </label>
                                <input type="text" name="address_zip" id="address_zip" value={getInfo('address_zip')} onChange={e => updateInfo('address_zip', e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="country_id">
                                    Country
                                </label>
                                <select name="country_id" id="country_id" value={getInfo('country_id')} onChange={e => updateInfo('country_id', e.target.value)} required>
                                    <option>Select Country</option>
                                    {countries.map((country, index) => {
                                        return (<option key={'country_' + index} value={country.country_id}>{country.country_name}</option>);
                                    })}
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="card-container">
                <div className="card-body">
                    <div className="row">
                        <div className="card-title">
                            Security
                        </div>

                        <div className="form-container">
                            <div className="form-group">
                                <label htmlFor="password">
                                    Password
                                </label>
                                <input type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="confirm_password">
                                    Confirm Password
                                </label>
                                <input type="password" name="confirm_password" id="confirm_password" value={confirmPass} onChange={e => setConfirmPass(e.target.value)}
                                       required />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-container btn-container">
                <button type="button" className="btn btn-primary" onClick={e => {
                    e.preventDefault();
                    submit();
                }}>
                    Save Changes
                </button>
            </div>

            <div className="card-container">
                <div className="card-body">
                    <div className="row">
                        <div className="card-title">
                            {user.role === 'contractor' ? 'Distributors' : 'Contractors'}
                        </div>

                        <div className="form-container">
                            {distributors.map((distributor, index) => {
                                return <div key={'distributor-' + index} className={'distributor'}>
                                    {distributor.profile_company} - {distributor.user_display_name}
                                </div>;
                            })}

                            {contractors.map((contractor, index) => {
                                return <div key={'contractor-' + index} className={'distributor'}>
                                    {contractor.profile_company} - {contractor.contractor_title}
                                </div>;
                            })}
                        </div>

                        {user.role === 'contractor' && (
                            <div className={'form-container pt-4'}>
                                <div>To request a distributor be added to your account please email: <a className={'link'} href="mailto:rewards@atlantic-oase.com">rewards@atlantic-oase.com</a></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
}
