import Axios from "axios";
import { store } from "../store";

export const Http = (options = {contentType: 'application/json'}) => {
    const state = store.getState();

    const instance = Axios.create({
        baseURL: '/',
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Content-Type': options.contentType,
            'Auth-Token': state.auth.token,
        },
    });

    if (process.env.NODE_ENV === 'development') {
        instance.interceptors.request.use(async config => {
            if (config.params) {
                config.params.XDEBUG_SESSION_START = 'PHPSTORM';
            } else {
                config.params = {
                    XDEBUG_SESSION_START: 'PHPSTORM',
                };
            }

            return config;
        });
    }

    instance.interceptors.response.use(
        (response) => {
            if (response.data.message) {
                console.log('responseMessage: ', response.data.message);
            }

            return response;
        },
        (error) => {
            if (error?.response?.status !== 401) {
                // don't alert 401
                if (error?.response?.data?.message) {
                    alert(error.response.data.message);
                } else if (error?.response?.data?.meta?.message) {
                    alert(error?.response.data.meta.message);
                }
            }

            if (error.response) {
                console.log('responseError: ', error.response);
            } else {
                console.log('errorMessage: ', error.message);
            }

            return Promise.reject(error);
        },
    );

    return instance;
};
