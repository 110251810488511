import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_ROOT } from '../../config';
import {Http} from '../../utils/Http';

export const fetchUser = createAsyncThunk('auth/fetch', async () => {
    return Http()
        .get(`${API_ROOT}/auth/validate`)
        .then((response) => ({
            user: response.data?.user,
        }));
});
