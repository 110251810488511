import React from 'react';
import closeIcon from "../../assets/img/icon/close-line.svg";

export const AlertModal = (props) => {


    return (
        <div className={"invoice-modal alert-modal " + (props.active ? 'active' : 'inactive')}>
            <div className="modal-card">
                <div className="card-header">
                    <div className="file-info">
                        <div>
                            <p>
                                {props.title ? props.title : 'Error'}
                            </p>
                        </div>
                    </div>

                    <div className="actions">
                        <button type="button" className="invoice-modal-close" onClick={() => {
                            props.close();
                        }}>
                            <img src={closeIcon} alt="Close" />
                        </button>
                    </div>
                </div>

                <div className="card-body alert-message">
                    {props.message}
                </div>

                {props.confirmAction && (
                    <div className="card-body alert-message">
                        <button type="button" className="invoice-modal-action action-confirm" onClick={() => {
                            props.confirmAction();
                        }}>
                            Confirm
                        </button>
                        <button type="button" className="invoice-modal-action action-deny" onClick={() => {
                            props.close();
                        }}>
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
