import React, {useState} from 'react';
import {BrowserRouter, Navigate, Routes} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Route} from "react-router-dom";
import {Welcome} from "./views/Welcome";
import {Dashboard} from "./components/Dashboard/Dashboard";
import {MobileHeader} from "./components/Header/MobileHeader";
import {Sidebar} from "./components/Sidebar/Sidebar";
import {PastRewardsList} from "./components/PastRewards/PastRewardsList";
import {ProductsList} from "./components/Products/ProductsList";
import {AccountSettings} from "./components/AccountSettings/AccountSettings";
import {Logout} from "./components/Logout/Logout";
import {SetPassword} from "./views/SetPassword";
import {TokenAuth} from "./components/TokenAuth/TokenAuth";
import {Invoices} from "./components/Invoices/Invoices";
import {ForgotPassword} from "./views/ForgotPassword";
import {ResetPassword} from "./views/ResetPassword";

export const Router = () => {
    const { user } = useSelector((state) => state.auth);
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

    return (
        <BrowserRouter>
            {!user ? (
                <Routes>
                    <Route path={'/'} exact element={<Welcome />} />
                    <Route path={'/token-auth'} element={<TokenAuth />} />
                    <Route path={'/set-password'} element={<SetPassword />} />
                    <Route path={'/forgot-password'} element={<ForgotPassword />} />
                    <Route path={'/reset-password'} element={<ResetPassword />} />
                </Routes>
            ) : (
                <div className={'full-height'}>
                    <MobileHeader setMobileMenuOpen={setMobileMenuIsOpen} />

                    <div className="main-container">

                        <Sidebar activePage={window.location.pathname} role={user?.role} mobileMenuIsOpen={mobileMenuIsOpen} />

                        {user?.role === 'distributor' && (
                            <Routes>
                                <Route path={'/'} element={<Navigate to={'/dashboard'} />} />
                                <Route path={'/dashboard'} element={<Dashboard userType={user?.role} />} />
                                <Route path={'/statements'} element={<Invoices />} />
                                <Route path={'/account-settings'} element={<AccountSettings />} />
                                <Route path={'/token-auth'} element={<TokenAuth />} />
                                <Route path={'/logout'} element={<Logout />} />
                            </Routes>
                        )}

                        {user?.role === 'contractor' && (
                            <Routes>
                                <Route path={'/'} element={<Navigate to={'/dashboard'} />} />
                                <Route path={'/dashboard'} element={<Dashboard userType={user?.role} />} />
                                <Route path={'/products'} element={<ProductsList />} />
                                <Route path={'/statements'} element={<Invoices />} />
                                <Route path={'/past-rewards'} element={<PastRewardsList />} />
                                <Route path={'/account-settings'} element={<AccountSettings />} />
                                <Route path={'/token-auth'} element={<TokenAuth />} />
                                <Route path={'/logout'} element={<Logout />} />
                            </Routes>
                        )}

                        {!user.roles && (
                            <Routes>
                                <Route path={'/token-auth'} element={<TokenAuth />} />
                                <Route path={'/logout'} element={<Logout />} />
                            </Routes>
                        )}
                    </div>
                </div>
            )}
        </BrowserRouter>
    );
}
