import React, {useRef} from "react";
import uploadIcon from '../../assets/img/icon/upload-2-line.svg';
import closeIcon from '../../assets/img/icon/close-line.svg';
import mobileCloseIcon from '../../assets/img/icon/mobile-close.svg'
import arrowLeftIcon from '../../assets/img/icon/arrow-left-line.svg';
import invoiceIcon from '../../assets/img/icon/file-line.svg';
import checkmarkIcon from '../../assets/img/icon/check-line.svg';
import deleteBinIcon from '../../assets/img/icon/delete-bin-fill.svg';
import addIcon from '../../assets/img/icon/add-circle-line.svg';
import {useEffect, useState} from "react";
import InvoiceService from "../../services/InvoiceService";
import UserService from "../../services/UserService";
import {API_ROOT} from "../../config";
import {AlertModal} from "../Modal/AlertModal";

const mockInvoiceItems = [];
for (let i = 1; i < 2; i++) {
    mockInvoiceItems.push({
        invoice_id: 1,
        invoice_item_item_id: '',
        invoice_item_description: '',
        invoice_item_price: '',
        invoice_item_quantity: '',
        invoice_item_is_awg_oase: '1',
        invoice_item_epdm: '',
    });
}

const emptyInvoiceItem = {
    invoice_id: '',
    invoice_item_item_id: '',
    invoice_item_description: '',
    invoice_item_price: '',
    invoice_item_quantity: '',
    invoice_item_is_awg_oase: '1',
    invoice_item_epdm: '',
}

export  const DistributorDashboard = () => {
    const [modalActive, setModalActive] = useState(false);

    const [newInvoiceItems, setNewInvoiceItems] = useState(mockInvoiceItems);
    const [viewingInvoice, setViewingInvoice] = useState(true);
    const [currentInvoice, setCurrentInvoice] = useState(false);
    const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(false);

    const [invoices, setInvoices] = useState([]);
    const [initialized, setInitialized] = useState(false);

    const [invoiceStart, setInvoiceStart] = useState(1);
    const [allInvoicesLoaded, setAllInvoicesLoaded] = useState(false);

    const [suggestedContractors, setSuggestedContractors] = useState([]);
    const [searching, setSearching] = useState(false);
    const [selectedContractor, setSelectedContractor] = useState({});
    const [selectedContractorName, setSelectedContractorName] = useState('');
    const [viewingContractorName, setViewingContractorName] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [invoiceRewardYear, setInvoiceRewardYear] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');

    const [fileIsHovered, setFileIsHovered] = useState(false);
    const [invoicePdfName, setInvoicePdfName] = useState('');

    const [uploadingInvoiceItems, setUploadingInvoiceItems] = useState([]);
    const [readyToImport, setReadyToImport] = useState(false);
    const [hasPossibleInvoiceErrors, setHasPossibleInvoiceErrors] = useState(false);
    const fileInputRef = useRef();
    const [isMobile, setIsMobile] = useState(false);

    const [productSearchItems, setProductSearchItems] = useState([]);
    const [searchingProductIndex, setSearchingProductIndex] = useState(null);

    const [alertModalActive, setAlertModalActive] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState('');
    const [alertModalAction, setAlertModalAction] = useState(null);
    const [alertModalTitle, setAlertModalTitle] = useState(null);

    useEffect(() => {
        InvoiceService.getAll().then(data => {
            setInvoices(data.invoices);

            if (invoices.length === data.total) {
                setAllInvoicesLoaded(true);
            }

            setInitialized(true);
        })
    }, []);

    const loadInvoices = () => {
        InvoiceService.getAll({page: invoiceStart + 1}).then(data => {
            const loadInvoices = invoices;
            loadInvoices.push(...data.invoices);
            setInvoices(loadInvoices);
            setInvoiceStart(invoiceStart + 1);

            if (loadInvoices.length === data.total) {
                setAllInvoicesLoaded(true);
            }
        })
    }

    const submitInvoice = () => {
        if (!invoiceNumber) {
            alert('Statement number is required.');
            return;
        }

        if (!invoicePdfName) {
            alert('File upload is required.');
            return;
        }

        InvoiceService.insert({
            contractor_id: selectedContractor.user_id,
            invoice_number: invoiceNumber,
            invoice_reward_year: invoiceRewardYear,
            invoice_file: invoicePdfName,
            invoice_items: newInvoiceItems
        }).then(response => {
            if (response.validated) {
                InvoiceService.getAll().then(data => {
                    setInvoices(data.invoices);

                    if (invoices.length === data.total) {
                        setAllInvoicesLoaded(true);
                    }

                    setModalActive(false);
                    setSelectedContractorName('');
                    setSelectedContractor({});
                    setInvoiceNumber('');
                    setInvoicePdfName('');
                    setNewInvoiceItems([{...emptyInvoiceItem}]);
                    setReadyToImport(false);
                    setUploadingInvoiceItems([]);
                    resetUploadForm();
                });
            } else {
                // clear any previously invalid items first
                for (let i = 0; i <= newInvoiceItems.length; i++) {
                    updateItemValue(i, 'invalid', false);
                }

                // highlight item
                for (const rejected of response.rejected) {
                    updateItemValue(rejected, 'invalid', true);
                }

                // alert
                alert(response.message);
            }
        });
    }

    const handleDeleteInvoice = () => {
        if(currentInvoice) {
            InvoiceService.deleteInvoice(currentInvoice.invoice_id)
            .then((response) => {

                invoices.splice(currentInvoiceIndex, 1);
                setModalActive(false);
                setAlertModalTitle('Success');
                setAlertModalMessage('Statement #' + invoiceNumber + ' deleted successfully');
                setAlertModalAction(null);
            });
        }
    }

    const handleCloseAlertModal = () => {
        setAlertModalActive(false);
        setAlertModalTitle(null);
        setAlertModalAction(null);
    }

    const getItemTotal = item => {
        if (parseFloat(item.invoice_item_price) && parseFloat(item.invoice_item_quantity)) {
            return '$' + (item.invoice_item_price * item.invoice_item_quantity).toFixed(2);
        }

        return '';
    }

    const getNewInvoiceTotal = () => {
        let total = 0.00;
        for (let i = 0; i < newInvoiceItems.length; i++) {
            let itemTotal = parseFloat(
                getItemTotal(newInvoiceItems[i]).replace('$', '')
            );

            if (itemTotal) {
                total += itemTotal;
            }
        }

        return '$' + total.toFixed(2);
    }

    const removeItem = index => {
        const newItems = [];
        for (let i = 0; i < newInvoiceItems.length; i++) {
            if (index !== i) {
                newItems.push(newInvoiceItems[i]);
            }
        }

        setNewInvoiceItems(newItems);
    }

    const getNewItemValue = (index, title) => {
        for (let i = 0; i < newInvoiceItems.length; i++) {
            if (i === index) {
                return newInvoiceItems[i][title];
            }
        }
    }

    const addItem = () => {
        const newItems = [];
        newItems.push(...newInvoiceItems);
        newItems.push({...emptyInvoiceItem});

        setNewInvoiceItems(newItems);
    }

    const updateItemValue = (index, key, value) => {
        const newItems = [];
        for (let i = 0; i < newInvoiceItems.length; i++) {
            if (index === i) {
                newInvoiceItems[i][key] = value;
            }

            newItems.push(newInvoiceItems[i]);
        }

        setNewInvoiceItems(newItems);
    }

    const openInvoiceModal = index => {
        setViewingInvoice(true);
        setNewInvoiceItems(invoices[index].invoice_items);
        setInvoicePdfName(invoices[index].invoice_file);
        setModalActive(true);
        setCurrentInvoice(invoices[index]);
        setCurrentInvoiceIndex(index);
    }

    const [searchTimer, setSearchTimer] = useState(0);

    const searchContractors = term => {
        setSearching(true);

        clearTimeout(searchTimer);
        setSearchTimer(
            setTimeout(() => {
                UserService.searchContractors(term).then(data => {
                    setSuggestedContractors(data.contractors);
                });
            }, 500)
        );
    };

    const searchProducts = term => {
        clearTimeout(searchTimer);
        setSearchTimer(
            setTimeout(() => {
                InvoiceService.searchProducts(term).then(data => {
                    setProductSearchItems(data.products);
                })
            }, 500)
        );
    }

    const resetUploadForm = () => {
        setSelectedContractor({});
        setSelectedContractorName('');
        setViewingContractorName('');
        setReadyToImport(false);

        const inactiveContent = document.querySelector('.upload-form-inactive-content');
        inactiveContent.classList.remove('hidden');

        const activeContent = document.querySelector('.upload-form-active-content');
        activeContent.classList.add('hidden');

        const mobileActiveContent = document.querySelector('.upload-form-active-content-mobile');
        mobileActiveContent.classList.add('hidden');

        const circle = document.querySelector('.progress-ring__circle');
        const radius = circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = circumference;
    }

    const uploadInvoice = files => {
        console.log('upload');
        const formData = new FormData();
        formData.append('file', files[0]);

        // switch to uploading
        const inactiveContent = document.querySelector('.upload-form-inactive-content');
        inactiveContent.classList.add('hidden');

        const activeContent = document.querySelector('.upload-form-active-content');
        activeContent.classList.remove('hidden');

        // set the file name
        document.querySelector('.invoice-file-name').innerHTML = files[0].name;
        document.querySelector('.upload-form-active-content-mobile .file').innerHTML = files[0].name;

        const circle = document.querySelector('.progress-ring__circle');
        const radius = circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;
        const progressElement = document.querySelector('.progress-value');
        const mobileProgressLine = document.querySelector('.upload-form-active-content-mobile .progress-line');
        const mobileProgressNumber = document.querySelector('.upload-form-active-content-mobile .number');

        const mobileActiveContent = document.querySelector('.upload-form-active-content-mobile');
        mobileActiveContent.classList.remove('hidden');

        let afterUploadProgress = 50;
        const uploadInterval = setInterval(() => {
            let incrementValue = Math.round(60 / 49);

            afterUploadProgress += incrementValue;
            if (afterUploadProgress < 100) {
                handleUploadProgress(afterUploadProgress);
            } else {
                clearInterval(uploadInterval);
            }
        }, 1000);

        let uploadRequestComplete = false;
        const handleUploadProgress = progress => {
            if (progress > 50 && !uploadRequestComplete) {
                progress = afterUploadProgress;
            } else if (progress >= 100 && !uploadRequestComplete) {
                progress = 99;
            } else if (uploadRequestComplete) {
                progress = 100;
            }

            document.querySelector('.invoice-mobile-upload').disabled = 'disabled';
            circle.style.strokeDashoffset = circumference - progress / 100 * circumference;
            progressElement.innerHTML = progress;

            mobileProgressLine.style.width = progress + '%';
            mobileProgressNumber.innerHTML = progress;
        };

        InvoiceService.uploadInvoice(formData, handleUploadProgress).then(data => {
            uploadRequestComplete = true;
            handleUploadProgress(100);

            document.querySelector('.invoice-mobile-upload').style.display = 'none';
            document.querySelector('.invoice-form-submit').style.display = 'block';

            if (data.message === 'Error processing invoice file') {
                resetUploadForm();

                // popup file may be too large
                setAlertModalMessage('Error. The file could not be processed.');
                setAlertModalActive(true);
            } else if (data.message) {
                setAlertModalMessage(data.message);
                setAlertModalActive(true);
            }

            if (data.invoiceNumber) {
                setInvoiceNumber(data.invoiceNumber);
            }

            if (data.invoiceDate) {
                setInvoiceDate(data.invoiceDate);
            }

            if (data.invoiceRewardYear) {
                setInvoiceRewardYear(new Date().getFullYear().toString());
            }

            setInvoicePdfName(data.invoiceFileName);

            for (const item of data.items) {
                item.invoice_item_is_awg_oase = '1';
            }

            setUploadingInvoiceItems(data.items);
            setHasPossibleInvoiceErrors(data.possibleErrors);

            setReadyToImport(true);
        });
    }

    useEffect(() => {
        if (isMobile && readyToImport === true) {
            populateInvoiceModal();
        }
    }, [readyToImport])

    // Clear viewing invoice id on modal close
    useEffect(() => {
        if (modalActive === false) {
            setCurrentInvoice(null);
            setCurrentInvoiceIndex(null);
        }
    }, [modalActive])

    const populateInvoiceModal = () => {
        if (selectedContractorName && !selectedContractor.user_id) {
            alert('Contractor not found');
            return;
        }

        if (!selectedContractor.user_id) {
            alert('Please select a contractor');
            return;
        }

        if (!invoicePdfName) {
            alert('File upload is required');
            return;
        }

        if (hasPossibleInvoiceErrors) {
            alert('Some of the values may not be correct. Please review statement items before submitting. We recommend uploading files of 50 or fewer lines.');
        }

        setNewInvoiceItems(uploadingInvoiceItems);

        if (!invoiceDate) {
            const date = new Date();
            const dateString = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

            setInvoiceDate(dateString);
        }

        if (!invoiceRewardYear) {
            const date = new Date();
            setInvoiceRewardYear(date.getFullYear());
        }

        setViewingInvoice(false);
        setCurrentInvoice(null);
        setModalActive(true);
    }

    useEffect(() => {
        const circle = document.querySelector('.progress-ring__circle');
        const radius = circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = circumference;
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (document.documentElement.clientWidth < 1300) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (modalActive) {
            window.scrollTo(0, 0);
            document.querySelector('body').classList.add('no-scroll');
        } else {
            document.querySelector('body').classList.remove('no-scroll');
        }
    }, [modalActive])

    return (
        <div>
            <div className="distributor-dashboard grid-50">
                <div className="left">
                    <div className="title-container">
                        <h2 className="title">
                            Uploaded Statements
                        </h2>

                        {!allInvoicesLoaded && (
                            <div className="mobile-view-more">
                                <a href="#" onClick={loadInvoices}>
                                    View More
                                </a>
                            </div>
                        )}
                    </div>

                    <div className="card-container">
                        {(initialized && invoices.length === 0) ? (
                            <div className="card">
                                <div className="top">
                                    <h2 className={'card-title'}>No Uploaded Statements</h2>
                                </div>
                            </div>
                        ) : null}
                        {invoices.map((invoice, index) => {
                            return (
                                <a href="#" className="card invoice" key={'invoice_' + index} onClick={() => {
                                    setViewingContractorName(invoice.contractor.profile_company + ' - ' + invoice.contractor.contractor_title);
                                    setInvoiceDate(invoice.invoice_date);
                                    setInvoiceRewardYear(invoice.invoice_reward_year ?? new Date(invoice.invoice_date).getFullYear());
                                    setInvoiceNumber(invoice.invoice_number);
                                    setInvoicePdfName(invoice.invoice_file);
                                    openInvoiceModal(index);
                                }}>
                                    <div className="top">
                                        <div className="card-title">
                                            {invoice.contractor.profile_company} - {invoice.contractor.contractor_title}
                                        </div>

                                        <div className="card-total">
                                            ${invoice.invoice_total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>
                                    </div>

                                    <div className="bottom">
                                        <span className="date">#{invoice.invoice_number}</span>
                                        <span className="dot">•</span>
                                        <span className="date">{invoice.invoice_date}</span>
                                        <span className="dot">•</span>
                                        <span className={invoice.invoice_status.toLowerCase()}>{invoice.invoice_status}</span>
                                    </div>
                                </a>
                            )
                        })}

                        {initialized && !allInvoicesLoaded && (
                            <div className="view-more">
                                <a href="#" onClick={loadInvoices}>
                                    View More
                                </a>
                            </div>
                        )}

                    </div>
                </div>

                <div className="right">
                    <h2 className="title">
                        Upload Statement
                    </h2>

                    <form method="POST" id="invoice_upload_form" className="upload-form" autoComplete={'off'}>
                        <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}} />
                        <input type="text" name="contractor_name_1" placeholder="Contractor Name or Company"
                               autoComplete={'none'}
                               className="contractor-name-input" value={selectedContractorName} onChange={e => {
                                   setSelectedContractorName(e.target.value);
                                   setViewingContractorName('');
                                   searchContractors(e.target.value);
                               }} required />

                            <div id="invoice_upload_container" className={"upload-form-content " + (fileIsHovered ? 'highlight' : '')}
                                 onDragEnter={e => {
                                     e.preventDefault();
                                     setFileIsHovered(true);
                                 }}
                                 onDragOver={e => {
                                     e.preventDefault();
                                     setFileIsHovered(true);
                                 }}
                                 onDragLeave={e => {
                                     e.preventDefault();
                                     setFileIsHovered(false);
                                 }}
                                 onDrop={e => {
                                     console.log('drop');
                                     e.preventDefault();
                                     setFileIsHovered(false);
                                     uploadInvoice(e.dataTransfer.files);
                                 }}
                            >
                                {searching && (
                                    <div className="search-results-container">
                                        {suggestedContractors.map((suggestedContractor, index) => {
                                            return (
                                                <div className="search-result" key={'suggested_' + index} onClick={() => {
                                                    setSelectedContractor(suggestedContractor)
                                                    setSelectedContractorName(suggestedContractor.profile_company + ' - ' + suggestedContractor.contractor_title)
                                                    setSearching(false);
                                                }}>
                                                    {suggestedContractor.profile_company} - {suggestedContractor.contractor_title}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                                <input type="file" id="invoice_upload_input" style={{display: 'none'}}
                                       accept=".pdf, .doc, .docx, .csv, .xlsx"
                                       ref={fileInputRef}
                                       onChange={e => uploadInvoice(e.target.files)}
                                       required />

                                    <div className="upload-form-inactive-content">
                                        <img src={uploadIcon} alt="Upload Icon"
                                             className="upload-icon" />

                                            <p className="upload-text">
                                                Drag & Drop or <label htmlFor="invoice_upload_input" onClick={() => {
                                                    fileInputRef.current.value = null;
                                            }}>Choose
                                                file</label> to upload
                                            </p>

                                            <p className="extensions">
                                                PDF, DOC, CSV, or Excel
                                            </p>
                                    </div>

                                    <div className="upload-form-active-content hidden">
                                        <div className="progress-ring-container">
                                            <svg className="progress-ring" height="220" width="220">
                                                <circle
                                                    strokeWidth="8"
                                                    stroke="#D9D9D9"
                                                    fill="transparent"
                                                    r="94"
                                                    cx="110"
                                                    cy="110"
                                                />
                                                <circle
                                                    className="progress-ring__circle"
                                                    strokeWidth="8"
                                                    stroke="#0F4C92"
                                                    fill="transparent"
                                                    r="94"
                                                    cx="110"
                                                    cy="110"
                                                    strokeDasharray="0 1"
                                                />
                                            </svg>

                                            <div className="progress-value-container">
                                                <p className="progress">
                                                    <span className="progress-value">0</span>%
                                                </p>
                                            </div>
                                        </div>

                                        <p className="uploading-file-description">
                                            Uploading&nbsp;<span className="invoice-file-name"></span> <img
                                            src={closeIcon} alt="Close Icon"
                                            onClick={() => {
                                                resetUploadForm();
                                            }}
                                            className="cancel-file-upload" />
                                        </p>
                                    </div>
                            </div>

                            <div className="upload-form-active-content-mobile hidden">
                                <div className="row">
                                    <div className="filename">
                                        Uploading <span className="file"></span>
                                    </div>

                                    <div className="close" onClick={() => {
                                        resetUploadForm();
                                    }}>
                                        <img src={mobileCloseIcon} alt="Close" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="progress-line-container">
                                        <div className="progress-line"></div>
                                    </div>


                                    <div className="progress-number-container">
                                        <span className="number">0</span>%
                                    </div>
                                </div>
                            </div>

                            <button type="button" className="invoice-mobile-upload" onClick={(e) => {
                                e.preventDefault();

                                setIsMobile(true);
                                fileInputRef.current.click();
                            }}>
                                Upload
                            </button>

                            <button type="submit" className="invoice-form-submit" form="invoice_upload_form" disabled={!readyToImport} onClick={(e) => {
                                e.preventDefault();

                                setViewingContractorName('');
                                setIsMobile(false);
                                populateInvoiceModal();
                            }}>
                                Import
                            </button>

                    </form>
                </div>
            </div>

            <AlertModal active={alertModalActive} close={() => handleCloseAlertModal()} message={alertModalMessage} confirmAction={alertModalAction} title={alertModalTitle} />

            <div className={"invoice-modal " + (modalActive ? 'active' : 'inactive')}>
                <div className="modal-card">
                    <div className="card-header">
                        <div className="file-info">
                            <img src={arrowLeftIcon} alt="Back" className="invoice-back-arrow"/>

                            <img src={invoiceIcon} className="invoice-icon"/>

                            <div>
                                <p>
                                    Statement #{invoiceNumber}
                                </p>

                                <a href={API_ROOT + '/uploaded/invoices/' + invoicePdfName} target="_blank">
                                    {invoicePdfName}
                                </a>
                            </div>
                        </div>

                        <div className="actions">
                            {viewingInvoice ? (
                                    currentInvoice?.invoice_payment_status !== 'Paid' && currentInvoice?.invoice_status !== 'Accepted' ? (
                                        <button type="button" className="btn btn-danger" onClick={() => { setAlertModalTitle("Delete Statement #" + invoiceNumber); setAlertModalAction(() => () => handleDeleteInvoice()); setAlertModalMessage("Are you sure you want to delete statement #" + invoiceNumber + "?"); setAlertModalActive(true);}}>
                                            Delete Statement
                                        </button>
                                    ) : null
                            ) : (
                                <button type="button" form="invoice_modal_form" className="btn btn-primary" onClick={submitInvoice}>
                                    Save & Submit
                                </button>
                            )}

                            <button type="button" className="invoice-modal-close" onClick={() => {
                                resetUploadForm();
                                setModalActive(false);
                            }}>
                                <img src={closeIcon} alt="Close" />
                            </button>
                        </div>
                    </div>

                    <form id="invoice_modal_form" className="card-body">
                        <div className="body-header">
                            <div className="name">
                                {viewingContractorName ? viewingContractorName : selectedContractorName}
                            </div>

                            <div className="date">
                                {!viewingInvoice && (
                                    <input type="text" placeholder={'Statement #'} value={invoiceNumber} className={'invoice-number-input'} onChange={e => {
                                        setInvoiceNumber(e.target.value);
                                    }} required/>
                                )}

                                <label className="stacked-input">
                                    Reward Year
                                    <input type="text" name="invoice_date" className="input-small" value={invoiceRewardYear} disabled={true} />
                                </label>
                                <input type="text" name="invoice_date" value={invoiceDate} disabled={true}
                                       // onChange={e => setInvoiceDate(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="product-grid">
                            <div className="grid-container">
                                <div className="grid-header">
                                    Product #
                                </div>

                                <div className="grid-header">
                                    Atlantic Item #
                                </div>

                                <div className="grid-header">
                                    Description
                                </div>

                                <div className="grid-header">
                                    Price
                                </div>

                                <div className="grid-header">
                                    QTY
                                </div>

                                <div className="grid-header">
                                    Total
                                </div>

                                <div className="grid-header">
                                    EPDM Liner
                                </div>

                                <div className="grid-header last-column"></div>
                            </div>


                            <div className="grid-container">
                                {newInvoiceItems.map((item, index) => {
                                    return (
                                        <React.Fragment key={'invoice-item-' + index}>
                                            <div className={"grid-item product-id "}>
                                                <label className="mobile">Product #</label>
                                                <input type="text" value={getNewItemValue(index, 'invoice_item_item_id')}
                                                       disabled={viewingInvoice}
                                                       onChange={e => {
                                                           updateItemValue(index, 'invoice_item_item_id', e.target.value);
                                                       }} onClick={() => setProductSearchItems([])} />
                                            </div>

                                            <div className={"grid-item item-id " + (getNewItemValue(index, 'invalid') ? 'invalid' : '')}>
                                                <label className="mobile">Atlantic Item #</label>
                                                <input type="text" value={getNewItemValue(index, 'atlantic_item_number')}
                                                       disabled={viewingInvoice}
                                                       onChange={e => {
                                                           updateItemValue(index, 'atlantic_item_number', e.target.value);
                                                           searchProducts(e.target.value);
                                                           setSearchingProductIndex(index);
                                                       }} onClick={() => setProductSearchItems([])} />

                                                {productSearchItems.length !== 0 && searchingProductIndex == index && (
                                                    <div className="product-number-search-container">
                                                        {productSearchItems.map(product => {
                                                            return <div className="product-number-search-item" onClick={() => {
                                                                updateItemValue(index, 'atlantic_item_number', product.product_item_number);
                                                                updateItemValue(index, 'invoice_item_description', product.product_title);
                                                                updateItemValue(index, 'invoice_item_is_awg_oase', '1');
                                                                setProductSearchItems([]);
                                                            }}>
                                                                {product.product_item_number} - {product.product_title}
                                                            </div>
                                                        })}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="grid-item description">
                                                <label className="mobile">Description</label>
                                                <input type="text" value={getNewItemValue(index, 'invoice_item_description')}
                                                       disabled={viewingInvoice}
                                                       onChange={e => updateItemValue(index, 'invoice_item_description', e.target.value)}
                                                       onClick={() => setProductSearchItems([])}
                                                />
                                            </div>

                                            <div className={"grid-item price-qty price " + (item?.possible_error ? 'error' : '')}>
                                                <label className="mobile">Price</label>
                                                <input type="text" value={
                                                    getNewItemValue(index, 'invoice_item_price')
                                                        ? '$' + getNewItemValue(index, 'invoice_item_price')
                                                        : ''
                                                } onChange={e => {
                                                    item.possible_error = false;
                                                    let price = e.target.value.replace('$', '');

                                                    updateItemValue(index, 'invoice_item_price', price);
                                                }} disabled={viewingInvoice} />
                                            </div>

                                            <div className="grid-item price-qty qty" style={isMobile ? {gridRow: index * 4 + 1} : {}}>
                                                <label className="mobile">Qty</label>
                                                <input type="text"
                                                       disabled={viewingInvoice}
                                                       value={getNewItemValue(index, 'invoice_item_quantity')} onChange={e => updateItemValue(index, 'invoice_item_quantity', e.target.value)} />
                                            </div>

                                            <div className="grid-item total">
                                                <label className="mobile">Total</label>
                                                <input type="text"
                                                       disabled={viewingInvoice}
                                                       value={getItemTotal(item)} readOnly />
                                            </div>

                                            <div
                                                className={"grid-item checkbox-container " + (getNewItemValue(index, 'invoice_item_epdm') == '1' ? 'checked' : '')}>

                                                <div className={"stylish-checkbox " + (viewingInvoice ? 'readonly' : '')} onClick={() => {
                                                    if (!viewingInvoice) {
                                                        updateItemValue(index, 'invoice_item_epdm', getNewItemValue(index, 'invoice_item_epdm') == '1' ? '0' : '1')
                                                    }
                                                }}>
                                                    <img src={checkmarkIcon} alt="Checkmark" className="check"/>
                                                </div>

                                                <label className="mobile">EPDM Liner</label>
                                            </div>

                                            <div className="grid-item trash">
                                                {viewingInvoice ? null : (
                                                    <img src={deleteBinIcon} alt="Delete" className="delete-item" onClick={() => removeItem(index)} />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>

                        {viewingInvoice ? null : (
                            <div className="add-product" onClick={() => addItem()}>
                                Add Product <img src={addIcon} />
                            </div>
                        )}
                    </form>

                    <div className="card-footer">
                        <div>
                            Total
                        </div>

                        <div className="products-total">
                            {getNewInvoiceTotal()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
