import {WelcomeHeader} from "../components/Header/WelcomeHeader";
import {WelcomeSlider} from "../components/Slider/WelcomeSlider";
import "../assets/styles/pages/home.scss"
import {ForgotPasswordForm} from "../components/Form/ForgotPasswordForm";

export const ForgotPassword = () => {
    return (
        <div>
            <WelcomeHeader />

            <div className="home grid-50">

                <WelcomeSlider />

                <ForgotPasswordForm />

            </div>
        </div>
    )
}
