import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {logout} from "../../store/reducers/auth";
import {useDispatch} from "react-redux";

export const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
        navigate('/');
    }, [])

    return (
        <></>
    );
}
