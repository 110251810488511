import {WelcomeHeader} from "../components/Header/WelcomeHeader";
import {WelcomeSlider} from "../components/Slider/WelcomeSlider";
import {WelcomeForm} from "../components/Form/WelcomeForm";
import "../assets/styles/pages/home.scss"

export const Welcome = () => {
    return (
        <div>
            <WelcomeHeader />

            <div className="home grid-50">

                <WelcomeSlider />

                <WelcomeForm />

            </div>
        </div>
    )
}
