import logo from '../../assets/img/AO_REWARDS.png';
import './style.scss';
import {useEffect, useState} from "react";
import AuthService from "../../services/AuthService";
import {useDispatch} from "react-redux";
import {setToken, setUser} from "../../store/reducers/auth";
import {useNavigate, useSearchParams} from "react-router-dom";

export const ForgotPasswordForm = () => {
    const [email, setEmail] = useState(null);

    const [message, setMessage] = useState(null);

    const login = async (e) => {
        e.preventDefault();

        if (!email) {
            setMessage('Please enter a valid email address');
            return;
        }

        try {
            AuthService.forgot(email).then(() => {
                setMessage('Check your email for instructions to reset your password.')
            });
        } catch (e) {
            console.log('err', e);
        }

    };

    return (
        <div className="home-form-container">
            <img src={logo} alt="logo" width="260" height="69"
                 className="form-logo tablet-hidden" />

            <form method="POST" className="form signin-container" onSubmit={e => login(e)}>
                <h1 className="title">Forgot Password</h1>

                {message && (
                    <div className="form-errors">
                        <p>{message}</p>
                    </div>
                )}

                <div className="input-container">
                    <input type="email" placeholder="Email" defaultValue={email} onChange={e => setEmail(e.target.value)} />
                </div>

                <input type="submit" value="Submit" className="btn btn-primary btn-wide submit" />
            </form>

            <p className="sign-up">
                Don’t have an account? <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=m8CUPfaJFUaKtC6U2t7_8hc_nmGsNwRKiJLssPIRfsFUOTlRNjdEMVFZR1pBQjNZS0lLNENURjlYRy4u">Click here to sign up</a>
            </p>
        </div>
    );
}
