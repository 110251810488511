import React from 'react';
import arrow from '../../assets/img/icon/arrow-left-s-line.svg';
import slide1 from '../../assets/img/slides/slide4.jpg';
import slide2 from '../../assets/img/slides/slide5.jpg';
import slide3 from '../../assets/img/slides/slide6.jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './styles.scss';
import Slider from 'react-slick';

export const WelcomeSlider = () => {
    const slider = React.useRef();

    const settings = {
        slide: '.slide',
        arrows: false,
    };

    return (
        <div className="home-slider-container">
            <div className="slide-arrow-container">
                <div className="slide-arrow-nav left" onClick={() => slider?.current?.slickPrev()}>
                    <img src={arrow} alt="slide left" />
                </div>

                <div className="slide-arrow-nav right" onClick={() => slider?.current?.slickNext()}>
                    <img src={arrow} alt="slide right" />
                </div>
            </div>
            <Slider ref={slider} {...settings}>
                <div className="slide">
                    <img src={slide1} alt="slide 1" />
                        <div className="overlay"></div>

                        <div className="caption">
                            <h2 className="slide-title">Start with crystal clear water</h2>

                            <p>
                                Add a FiltoClear inline to deliver crystal clear water in open basin applications
                            </p>
                        </div>
                </div>

                <div className="slide">
                    <img src={slide2} alt="slide 2" />
                        <div className="overlay"></div>

                        <div className="caption">
                            <h2 className="slide-title">All-in-one Kits</h2>

                            <p>
                                From single vases to triple vase kits, utilize our pre-matched kits for fast installations
                            </p>
                        </div>
                </div>

                <div className="slide">
                    <img src={slide3} alt="slide 3" />
                    <div className="overlay"></div>

                    <div className="caption">
                        <h2 className="slide-title">Natural beauty for any aesthetic</h2>

                        <p>
                            Natural Basalt Columns, available individually or as kits, bring the sight and sound of water to any landscape
                        </p>
                    </div>
                </div>
            </Slider>
        </div>
    );
}
